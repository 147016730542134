import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "quotes" }
const _hoisted_2 = {
  key: 0,
  class: "container-fluid"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quote_details = _resolveComponent("quote-details")!
  const _component_resource_list = _resolveComponent("resource-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_quote_details, {
      ref: "resource-details",
      onGetResources: _ctx.getQuotes
    }, null, 8, ["onGetResources"]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_resource_list, {
            columnDefs: _ctx.columnDefs,
            rowData: _ctx.rowData,
            resourceName: 'Quotes',
            pagination: true,
            paginationPageSize: 10,
            frameworkComponents: _ctx.frameworkComponents,
            editType: _ctx.editType,
            paginationData: _ctx.paginationData,
            btn_options: _ctx.btn_options,
            onSuccess: _ctx.editResource,
            onGetRows: _ctx.getQuotes,
            onShowDetails: _ctx.showQuote
          }, null, 8, ["columnDefs", "rowData", "frameworkComponents", "editType", "paginationData", "btn_options", "onSuccess", "onGetRows", "onShowDetails"])
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, "Data loading..."))
  ]))
}