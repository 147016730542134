
import { defineComponent } from "vue";
export default defineComponent({
  name: "quote-details",
  data() {
    return {
      validatedForm: {},
      button: "Add",
      icon: "fa fa-plus",
      title: "Quote Details",
      quote: {},
      schema: [],
      submitButton: {
        class: "btn bg-theme bt-submit mt-3 float-start",
        value: "Submit",
        loading: "Submiting...",
      },
    };
  },
  methods: {
    async showDetailModal(data: any) {
      this.quote = data;
      console.log("container-fluid", this.quote);
      this.$refs.toggleBtn.click();
    },
  },
});
